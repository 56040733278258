<template>
	<h2 style="margin: 0 auto; text-align: center;margin-top: 4%;color:#409EFF ;" >键盘按键KeyCode码</h2>
		<el-row style="margin-top: 4%;">
			<el-col :sm="11" style="margin: 0 auto; text-align: center;">
				<el-form :model="keyBoardData">
					<el-row :gutter="20" >
						<el-col :sm = "24">
							<el-form-item label="键盘按键">
								<el-input v-model="keyBoardData.keyBoardVal" @keydown='generate' maxlength=1  placeholder="请输入按键" >
									<template v-slot:append>{{keyBoardData.keyPress}}</template>
								</el-input>
							</el-form-item>
						</el-col>
					</el-row>
					
					<el-row :gutter="20" >
						<el-table :data="keyBoardData.tableOneData"  border style="width: 100%" :row-class-name="tableRowClassName">
							<el-table-column label="字母和数字键的键码值(keyCode)" align="center">
								<el-table-column prop="column1" label="按键" width="108"></el-table-column>
								<el-table-column prop="column2" label="键码" width="108"></el-table-column>
								<el-table-column prop="column3" label="按键" width="108"></el-table-column>
								<el-table-column prop="column4" label="键码" width="108"></el-table-column>
								<el-table-column prop="column5" label="按键" width="108"></el-table-column>
								<el-table-column prop="column6" label="键码" width="108"></el-table-column>
								<el-table-column prop="column7" label="按键" width="108"></el-table-column>
								<el-table-column prop="column8" label="键码" width="110"></el-table-column>
							</el-table-column>
						  </el-table>
					</el-row>
					
					<el-row :gutter="20" style="margin-top: 2%;">
						<el-table :data="keyBoardData.tableTwoData"  border style="width: 100%" :row-class-name="tableRowClassName">
							<el-table-column label="数字键盘上的键的键码值(keyCode)" align="center">
								<el-table-column prop="column1" label="按键" width="108"></el-table-column>
								<el-table-column prop="column2" label="键码" width="108"></el-table-column>
								<el-table-column prop="column3" label="按键" width="108"></el-table-column>
								<el-table-column prop="column4" label="键码" width="108"></el-table-column>
							</el-table-column>
							<el-table-column label="功能键键码值(keyCode)" align="center">
								<el-table-column prop="column5" label="按键" width="108"></el-table-column>
								<el-table-column prop="column6" label="键码" width="108"></el-table-column>
								<el-table-column prop="column7" label="按键" width="108"></el-table-column>
								<el-table-column prop="column8" label="键码" width="110"></el-table-column>
							</el-table-column>
						  </el-table>
					</el-row>
					
					<el-row :gutter="20" style="margin-top: 2%;">
						<el-table :data="keyBoardData.tableThreeData"  border style="width: 100%" :row-class-name="tableRowClassName">
							<el-table-column label="控制键键码值(keyCode)" align="center">
								<el-table-column prop="column1" label="按键" width="108"></el-table-column>
								<el-table-column prop="column2" label="键码" width="108"></el-table-column>
								<el-table-column prop="column3" label="按键" width="108"></el-table-column>
								<el-table-column prop="column4" label="键码" width="108"></el-table-column>
								<el-table-column prop="column5" label="按键" width="108"></el-table-column>
								<el-table-column prop="column6" label="键码" width="108"></el-table-column>
								<el-table-column prop="column7" label="按键" width="108"></el-table-column>
								<el-table-column prop="column8" label="键码" width="110"></el-table-column>
							</el-table-column>
						  </el-table>
					</el-row>
					
					<el-row :gutter="20" style="margin-top: 2%;">
						<el-table :data="keyBoardData.tableFourData"  border style="width: 100%" :row-class-name="tableRowClassName">
							<el-table-column label="控制键键码值(keyCode)" align="center">
								<el-table-column prop="column1" label="按键" width="108"></el-table-column>
								<el-table-column prop="column2" label="键码" width="108"></el-table-column>
								<el-table-column prop="column3" label="按键" width="108"></el-table-column>
								<el-table-column prop="column4" label="键码" width="108"></el-table-column>
								<el-table-column prop="column5" label="按键" width="108"></el-table-column>
								<el-table-column prop="column6" label="键码" width="108"></el-table-column>
								<el-table-column prop="column7" label="按键" width="108"></el-table-column>
								<el-table-column prop="column8" label="键码" width="110"></el-table-column>
							</el-table-column>
						  </el-table>
					</el-row>
					
				</el-form>
		</el-col>
	</el-row>
</template>

<script setup>
import {ref} from 'vue'
import {moduleEvent as moduleaction} from '../../common/actionrecord'

    let keyBoardData = ref({
		moduleid:1005,
        keyPress: "",
        tableOneData: [
			{column1:'A',column2:'65',column3:'J',column4:'74',column5:'S',column6:'83',column7:'1',column8:'49'},
			{column1:'B',column2:'66',column3:'K',column4:'75',column5:'T',column6:'84',column7:'2',column8:'50'},
			{column1:'C',column2:'67',column3:'L',column4:'76',column5:'U',column6:'85',column7:'3',column8:'51'},
			{column1:'D',column2:'68',column3:'M',column4:'77',column5:'V',column6:'86',column7:'4',column8:'52'},
			{column1:'E',column2:'69',column3:'N',column4:'78',column5:'W',column6:'87',column7:'5',column8:'53'},
			{column1:'F',column2:'70',column3:'O',column4:'79',column5:'X',column6:'88',column7:'6',column8:'54'},
			{column1:'G',column2:'71',column3:'P',column4:'80',column5:'Y',column6:'89',column7:'7',column8:'55'},
			{column1:'H',column2:'72',column3:'Q',column4:'81',column5:'Z',column6:'90',column7:'8',column8:'56'},
			{column1:'I',column2:'73',column3:'R',column4:'82',column5:'0',column6:'48',column7:'9',column8:'57'},
		],
		tableTwoData: [
			{column1:'0',column2:'96',column3:'8',column4:'104',column5:'F1',column6:'112',column7:'F9',column8:'120'},
			{column1:'1',column2:'97',column3:'9',column4:'105',column5:'F2',column6:'113',column7:'F10',column8:'121'},
			{column1:'2',column2:'98',column3:'*',column4:'106',column5:'F3',column6:'114',column7:'F11',column8:'122'},
			{column1:'3',column2:'99',column3:'+',column4:'107',column5:'F4',column6:'115',column7:'F12',column8:'123'},
			{column1:'4',column2:'100',column3:'Enter',column4:'108',column5:'F5',column6:'116',column7:'',column8:''},
			{column1:'5',column2:'101',column3:'-',column4:'109',column5:'F5',column6:'117',column7:'',column8:''},
			{column1:'6',column2:'102',column3:'.',column4:'110',column5:'F6',column6:'118',column7:'',column8:''},
			{column1:'7',column2:'103',column3:'/',column4:'111',column5:'F7',column6:'119',column7:'',column8:''},
		],
		tableThreeData: [
			{column1:'BackSpace',column2:'8',column3:'Esc',column4:'27',column5:'Right Arrow',column6:'39',column7:'-_',column8:'189'},
			{column1:'Tab',column2:'9',column3:'Spacebar',column4:'32',column5:'Dw Arrow',column6:'40',column7:'.>',column8:'190'},
			{column1:'Clear',column2:'12',column3:'Page Up',column4:'33',column5:'Insert',column6:'45',column7:'/?',column8:'191'},
			{column1:'Enter',column2:'13',column3:'Page Down',column4:'34',column5:'Delete',column6:'46',column7:'`~',column8:'192'},
			{column1:'Shift',column2:'16',column3:'End',column4:'35',column5:'Num Lock',column6:'144',column7:'[{',column8:'219'},
			{column1:'Control',column2:'17',column3:'Home',column4:'36',column5:';:',column6:'186',column7:'/|',column8:'220'},
			{column1:'Alt',column2:'18',column3:'Left Arrow',column4:'37',column5:'=+',column6:'187',column7:']}',column8:'221'},
			{column1:'Cape Lock',column2:'20',column3:'Up Arrow',column4:'38',column5:',<',column6:'188',column7:'"',column8:'222'},
		],
		tableFourData: [
			{column1:'音量加',column2:'175',column3:'',column4:'',column5:'',column6:'',column7:'',column8:''},
			{column1:'音量减',column2:'174',column3:'',column4:'',column5:'',column6:'',column7:'',column8:''},
			{column1:'停止',column2:'179',column3:'',column4:'',column5:'',column6:'',column7:'',column8:''},
			{column1:'静音',column2:'173',column3:'',column4:'',column5:'',column6:'',column7:'',column8:''},
			{column1:'浏览器',column2:'172',column3:'',column4:'',column5:'',column6:'',column7:'',column8:''},
			{column1:'邮件',column2:'180',column3:'',column4:'',column5:'',column6:'',column7:'',column8:''},
			{column1:'搜索',column2:'170',column3:'',column4:'',column5:'',column6:'',column7:'',column8:''},
			{column1:'收藏',column2:'171',column3:'',column4:'',column5:'',column6:'',column7:'',column8:''},
		]
    })
	
	moduleaction(keyBoardData.value.moduleid,1);
    let generate = (event) => {
		keyBoardData.value.keyBoardVal=""
		keyBoardData.value.keyPress=event.keyCode
		//模块事件 1.预览 2.使用
		moduleaction(keyBoardData.value.moduleid,2);
    }
</script>

<style>

</style>